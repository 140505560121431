import styled from "styled-components";

export const FindMeMap = styled.div`
    position: absolute !important;
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
    img {
        object-fit: cover;
    }
    @media (min-width: 768px) {
        width: 50%;
    }
`;

export const FindMeBG = styled.div`
    position: absolute !important;
    width: 100%;
    height: 100%;
    background: red;
    top: 0;
    left: 0;
    opacity: 0.1;
`;

export const FindMeButtons = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    a {
        border: none;
        height: 52px;
        width: 135px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        margin: 0 10px;
        margin-top: 20px;
        img {
            object-fit: contain;
        }
    }
`;

export const FindMeCard = styled.div`
    min-height: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    position: relative;
    padding: 0px 5rem;
    background: linear-gradient(
            rgba(32, 93, 95, 0.95) 0%,
            rgba(30, 70, 90, 0.95) 100%
        ),
        url("https://cdn.zakariabenali.me/images/architecture-bg.webp")
            no-repeat center;
    box-shadow: rgb(55 111 116 / 30%) 0px 52px 27px -37px;
    > * {
        position: relative;
    }
    .map-image {
        opacity: 0.6;
    }
    .section-title {
        text-align: center;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        padding: 2.5rem 2rem;
    }
`;
