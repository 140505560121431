import styled from "styled-components";

export const TechnologyElement = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
    width: 50%;
    font-size: 0.8rem;
    font-weight: 500;
    &:nth-child(odd) {
        padding-right: 5px;
    }
    &:nth-child(even) {
        padding-left: 5px;
    }
    img {
        flex-shrink: 0;
        margin-right: 10px !important;
    }
`;

export const TechnologiesContainer = styled.ul`
    display: flex;
    flex-wrap: wrap;
`;

export const TechnologiesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    position: relative;
    margin-top: 4rem;
    padding: 0 30px;
    > * {
        position: relative;
        z-index: 1;
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        box-shadow: rgb(24 39 75 / 12%) 0px 6px 12px -6px,
            rgb(24 39 75 / 8%) 0px 8px 24px -4px;
        z-index: 0;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${props => props.theme.palette.gradient.services};
    }
    .section-title {
        margin-top: 2.5rem;
        margin-bottom: 1.25rem;
    }
    hr {
        width: 100%;
        background: ${props => props.theme.palette.common.borderColor};
        opacity: 0.2;
    }

    @media (min-width: 600px) {
        padding: 5rem;
        .section-title {
            margin-top: 0;
        }
        hr {
            margin: 1.875rem 0 3.125rem 0;
        }
        ${TechnologyElement} {
            font-size: 1rem;
        }
    }

    @media (min-width: 768px) {
        ${TechnologyElement} {
            width: calc(100% / 3);
            padding: 0 !important;
        }
    }

    @media (min-width: 1024px) {
        ${TechnologyElement} {
            width: calc(100% / 4);
        }
    }

    @media (min-width: 1280px) {
        ${TechnologyElement} {
            width: 140px !important;
            margin-bottom: 2.5rem !important;
            margin-right: 3.75rem !important;
        }
    }
`;

export const ServiceSingle = styled.div`
    margin-bottom: 0.625rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1rem;
    margin-right: 1.25rem;
    margin-bottom: 1.25rem;
    svg {
        margin-right: 0.625rem;
        fill: ${props => props.theme.palette.primary.main};
    }
`;

export const ServicesContainer = styled.div`
    margin-top: 1.875rem;
    display: flex;
    flex-wrap: wrap;
`;

export const About = styled.div`
    padding-left: 1.5625rem;
    margin-top: 1.25rem;
    border-left: 2px solid ${props => props.theme.palette.primary.main};
    .section-title {
        margin-bottom: 1.25rem;
        h2 {
            font-size: 1.3rem;
        }
    }
    p {
        line-height: 1.6875rem;
        margin-bottom: 1.25rem;
        max-width: 65ch;
    }
`;

export const ServiceSectionHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: 1024px) {
        flex-direction: row;
        ${About} {
            margin-top: 0;
        }
    }
`;

export const ServiceSection = styled.section`
    position: relative;
    > * {
        position: relative;
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 70%;
        background: ${props => props.theme.palette.bgPrimaryLight};
    }
`;
