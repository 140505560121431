import React, { ButtonHTMLAttributes } from "react";
import { ButtonContainer, ButtonSpinner } from "./style";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode;
    isLoading?: boolean;
    secondary?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, Props>(
    (props, ref) => {
        const { children, isLoading, secondary, ...rest } = props;
        return (
            <ButtonContainer
                ref={ref}
                isLoading={isLoading}
                secondary={secondary}
                className="button"
                {...rest}
            >
                {isLoading && <ButtonSpinner secondary={secondary} />}
                {children}
            </ButtonContainer>
        );
    }
);
