import React, { useEffect } from "react";
import VanillaTilt from "vanilla-tilt";
import {
    ProjectCardContainer,
    ProjectCardWrapper,
    ProjectDesc,
    ProjectName,
    TechnologiesContainer,
    Technology,
    ProjectImage,
    ProjectImageWrapper
} from "./style";
import { Project } from "types/Project";
import Image from "next/image";
interface IProps {
    project: Project;
}

export const ProjectCard: React.FC<IProps> = ({ project }) => {
    const anchorTag = React.createRef<HTMLAnchorElement>();
    const {
        title,
        showcase,
        description,
        stack,
        link,
        upper_color,
        lower_color
    } = project;
    useEffect(() => {
        const options = {
            "scale": 1,
            "speed": 1000,
            "max": 5,
            "glare": true,
            "max-glare": 0.2
        };

        const anchor = anchorTag.current;
        if (anchor) {
            VanillaTilt.init(anchor, options);
        }
    }, [anchorTag]);
    const bgColor = `linear-gradient(${upper_color} 0%, ${lower_color} 100%)`;

    return (
        <ProjectCardWrapper>
            <ProjectCardContainer
                bgColor={bgColor}
                href={link}
                ref={anchorTag}
                target="_blank"
            >
                <ProjectImage>
                    <ProjectImageWrapper>
                        <Image
                            src={showcase.url}
                            alt={`${title} Project Showcase`}
                            sizes="(max-width:1024px) 100vw, 35vw"
                            fill
                        />
                    </ProjectImageWrapper>
                </ProjectImage>

                <ProjectName>{title}</ProjectName>
                <ProjectDesc>{description}</ProjectDesc>
                <TechnologiesContainer>
                    {stack.slice(0, 5).map((technology, i) => (
                        <Technology key={i}>
                            <Image
                                alt="Technology icon"
                                className="tech-icon"
                                height="15"
                                width="15"
                                src={technology.icon.url}
                            />
                            {technology.name}
                        </Technology>
                    ))}
                    {stack.length > 5 && (
                        <Technology>+{stack.length - 5} more</Technology>
                    )}
                </TechnologiesContainer>
            </ProjectCardContainer>
        </ProjectCardWrapper>
    );
};
