import styled from "styled-components";

export const BenefitSingle = styled.div`
    display: flex;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 1.25rem;
    svg {
        margin-right: 10px;
        path {
            fill: ${props => props.theme.palette.primary.main};
        }
    }
`;

export const BenefitWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        line-height: 1.5625rem;
        color: ${props => props.theme.palette.common.bodyText};
        margin-bottom: 1.875rem;
        max-width: 35ch;
    }
    img {
        object-fit: contain;
        max-width: 100%;
    }
    &:last-child {
        margin-top: 3.75rem;
    }
    @media (max-width: 1024px) {
        align-items: flex-start;
        flex-direction: column !important;
    }
`;

export const WhySection = styled.section`
    ${BenefitWrapper}:last-child {
        flex-direction: row-reverse;
    }
    .container {
        display: flex;
        flex-direction: column;
    }
`;
