import styled from "styled-components";

export const SubHeading = styled.p`
    color: ${props => props.theme.palette.primary.main};
    font-size: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
`;

export const Heading = styled.h2`
    font-size: 1.56rem;
    font-weight: 700;
    max-width: 305px;
    margin-top: 0.3125rem;
    margin-bottom: 1.25rem;
    @media (min-width: 600px) {
        font-size: 2.25rem;
    }
`;
