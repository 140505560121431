import { Container } from "components/ui/Container";
import { NextExternalImage } from "components/ui/NextExternalImage";
import { SectionTitle } from "components/ui/SectionTitle";
import { FindMeButtons, FindMeCard, FindMeMap } from "./style";

export const FindMe: React.FC = () => {
    return (
        <section id="find-me">
            <Container>
                <FindMeCard>
                    <FindMeMap>
                        <NextExternalImage
                            fill
                            src="map.png"
                            className="map-image"
                            alt="Photo of dotted map"
                            sizes="(max-width:600px) 100vw, 40vw"
                        />
                    </FindMeMap>

                    <SectionTitle heading="Find me on" />
                    <FindMeButtons>
                        <a
                            href="https://www.fiverr.com/benalizakaria"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <NextExternalImage
                                height="20"
                                width="68"
                                src="fiverr.svg"
                                alt="Fiverr Logo"
                            />
                        </a>
                        <a
                            href="https://www.upwork.com/freelancers/~010aaf53dde57b55f8"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <NextExternalImage
                                height="20"
                                width="68"
                                src="upwork.svg"
                                alt="Upwork Logo"
                            />
                        </a>
                    </FindMeButtons>
                </FindMeCard>
            </Container>
        </section>
    );
};
