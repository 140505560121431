import { NextSeo } from "next-seo";

export function HomeSeo() {
    return (
        <NextSeo
            title="Full stack developer and UI/UX Designer"
            description="A Full stack And UI/UX Designer, Worked on various types of projects and I'm looking to exchange me experience with you"
            canonical="https://www.zakariabenali.me/"
            openGraph={{
                url: "https://www.zakariabenali.me/",
                images: [
                    {
                        url: `${process.env.NEXT_PUBLIC_CDN}/images/seo.png`,
                        alt: "An image with some of the main technologies i use"
                    }
                ],
                site_name: "Zakariabenali",
                type: "website"
            }}
            twitter={{
                handle: "@handle",
                site: "@site",
                cardType: "summary_large_image"
            }}
        />
    );
}
