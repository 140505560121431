import { motion } from "framer-motion";
import { PropsWithChildren } from "react";

interface Props {
    delay?: number;
}

const FadeUp: React.FC<PropsWithChildren<Props>> = ({ delay, children }) => {
    return (
        <motion.div
            initial="initial"
            animate="animate"
            variants={{
                initial: {
                    opacity: 0,
                    y: 50
                },
                animate: {
                    opacity: 1,
                    y: 0
                }
            }}
            transition={{ delay: delay }}
        >
            {children}
        </motion.div>
    );
};

FadeUp.defaultProps = {
    delay: 0
};

export { FadeUp };
