import styled, { css } from "styled-components";
import { spinCenter } from "components/ui/animations";

interface IButtonProps {
    secondary?: boolean;
    isLoading?: boolean;
}

export const ButtonContainer = styled.button<IButtonProps>`
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    height: 3.4375rem;
    width: 10.6875rem;
    font-size: 1rem;
    background: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.contrastText};
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 2px solid ${props => props.theme.palette.primary.main};
        transform: scale(1.2);
        opacity: 0;
        transition: 0.3s ease;
        pointer-events: none;
    }
    &:hover:after {
        transform: scale(1);
        opacity: 1;
    }
    ${props =>
        props.isLoading &&
        css`
            pointer-events: none;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: ${props.secondary
                    ? props.theme.palette.secondary.main
                    : props.theme.palette.primary.main};
            }
        `}
    ${props =>
        props.secondary &&
        css`
            background: ${props.theme.palette.secondary.main};
            color: ${props.theme.palette.secondary.contrastText};
        `}
`;

export const ButtonSpinner = styled.div<IButtonProps>`
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid
        ${props =>
            props.secondary
                ? props.theme.palette.secondary.contrastText
                : props.theme.palette.primary.contrastText};
    border-bottom-color: transparent;
    transform: translate(-50%, -50%);
    animation: ${spinCenter} 1s linear infinite;
`;
