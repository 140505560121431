import { Container } from "components/ui/Container";
import { SectionTitle } from "components/ui/SectionTitle";
import { Project } from "types/Project";
import { ProjectCard } from "./ProjectCard";
import { ProjectContainer } from "./style";

interface IProps {
    projects: Project[];
}

export const Work: React.FC<IProps> = ({ projects }) => {
    return (
        <section id="work" className="body-section">
            <Container>
                <SectionTitle
                    heading="Various types of projects"
                    subHeading="Work"
                    headingId="all-projects-heading"
                />
                <ProjectContainer aria-labelledby="all-projects-heading">
                    {projects.map((project, i) => (
                        <ProjectCard key={i} project={project} />
                    ))}
                </ProjectContainer>
            </Container>
        </section>
    );
};
