import { BgCircle } from "./style";

const BgCircles = () => {
    return (
        <>
            <BgCircle height={130} delay={0.06} />
            <BgCircle height={100} delay={0.12} />
            <BgCircle height={75} delay={0.16} />
            <BgCircle height={60} delay={0.18} />
            <BgCircle height={40} delay={0.2} />
            <BgCircle height={20} delay={0.22} />
        </>
    );
};

export { BgCircles };
