import { Container } from "components/ui/Container";
import { SectionTitle } from "components/ui/SectionTitle";
import { BenefitSingle, BenefitWrapper, WhySection } from "./style";
import { RoundedSMCheck } from "components/ui/Icons";
import { NextExternalImage } from "components/ui/NextExternalImage";

export const WhyMe: React.FC = () => {
    return (
        <WhySection id="why" className="body-section">
            <Container>
                <BenefitWrapper>
                    <div>
                        <SectionTitle heading="Scalable Coding." />
                        <p>
                            As a developer, I know the struggle of maintaining
                            an existing project with a bad base code or
                            practices that&apos;s why I create code for humans
                            to read and for machines to execute.
                        </p>
                        <BenefitSingle>
                            <RoundedSMCheck />
                            Clean code.
                        </BenefitSingle>
                        <BenefitSingle>
                            <RoundedSMCheck />
                            Well documented code.
                        </BenefitSingle>
                        <BenefitSingle>
                            <RoundedSMCheck />
                            Performant and secure.
                        </BenefitSingle>
                        <BenefitSingle>
                            <RoundedSMCheck />
                            Scalable and easy to maintain.
                        </BenefitSingle>
                    </div>
                    <NextExternalImage
                        alt="Floating laptops With UI Design Preview"
                        src="carbon.webp"
                        sizes="(max-width:600px) 100vw, 40vw"
                        height="400"
                        width="700"
                    />
                </BenefitWrapper>
                <BenefitWrapper>
                    <div>
                        <SectionTitle heading="Elegant Design." />
                        <p>
                            On average a user stays on a page for 10-20 seconds,
                            In those few seconds, it is my job to attract the
                            user and convert it into a customer.
                        </p>
                        <BenefitSingle>
                            <RoundedSMCheck />
                            User interface design.
                        </BenefitSingle>
                        <BenefitSingle>
                            <RoundedSMCheck />
                            User experience design.
                        </BenefitSingle>
                        <BenefitSingle>
                            <RoundedSMCheck />
                            Pixel Perfect.
                        </BenefitSingle>
                    </div>
                    <NextExternalImage
                        alt="Floating laptops With UI Design Preview"
                        src="floating_laptops.webp"
                        sizes="(max-width:600px) 100vw, 40vw"
                        height="500"
                        width="700"
                    />
                </BenefitWrapper>
            </Container>
        </WhySection>
    );
};
