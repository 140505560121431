export enum TechnologyType {
    DEV = "dev",
    DESIGN = "design"
}

export type Technology = {
    id: number;
    name: string;
    type: TechnologyType;
    icon: {
        url: string;
    };
};
