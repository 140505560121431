import styled from "styled-components";

export const SubHeading = styled.h2`
    font-size: 2em;
    font-weight: 500;
    position: relative;
`;

export const Heading = styled.h1`
    font-size: 2.625em;
    font-weight: 700;
    margin-top: 0.625rem;
    margin-bottom: 1.875rem;
    position: relative;
`;

export const LeadingText = styled.p`
    position: relative;
    max-width: 500px;
    line-height: 27px;
    font-size: 1.25em;
    color: ${props => props.theme.palette.common.bodyText};
`;

export const HeroButtons = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 3.75rem;
    margin-bottom: 5rem;
    position: relative;
    .button {
        margin-bottom: 1.25rem;
        &:first-child {
            margin-right: 1.25rem;
        }
    }
`;

interface BgCirclesProps {
    height: number;
    delay?: number;
}

export const BgCircle = styled.div.attrs({
    className: "bg-circle"
})<BgCirclesProps>`
    height: ${props => props.height}vh;
    width: ${props => props.height}vh;
    position: absolute;
    border-radius: 100%;
    top: 50%;
    left: 50%;
    border: 1px solid ${props => props.theme.palette.common.borderColor};
    transform: translate(-50%, -50%);
    opacity: 0.5;
    transition: 0.3s ease;
    transition-delay: ${props => props.delay}s;
`;

BgCircle.defaultProps = {
    height: 90,
    delay: 0
};

export const HeroService = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px;
    margin-right: 30px;
    margin-bottom: 15px;
    background: rgba(255, 255, 255, 0.04);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border: 2px solid transparent;
    border-image: ${props =>
            `linear-gradient(to right, ${props.theme.palette.common.borderColor}, ${props.theme.palette.bgColor})`}
        2;
`;

export const HeroServices = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const HeroSection = styled.section`
    font-size: 0.9rem;
    background: ${props => props.theme.palette.gradient.main};
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    .container {
        position: relative;
        z-index: 2;
    }
    @media (min-width: 1024px) {
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
        font-size: 1.1rem;
        ${LeadingText} {
            line-height: 2.1875rem;
        }
    }
    @media (max-width: 768px) {
        padding-top: 9.375rem;
        padding-bottom: 3.75rem;
        align-items: center;
        ${HeroButtons} {
            margin-bottom: 1.25rem;
        }
    }
`;
