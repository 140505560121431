import styled from "styled-components";

export const ProjectImage = styled.div`
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
    position: absolute;
    display: none;
`;

export const ProjectDesc = styled.p`
    color: rgba(255, 255, 255, 0.6);
    margin-top: 1.25rem;
    max-width: 28ch;
    margin-bottom: auto;
    transform: translateZ(20px);
`;

export const ProjectName = styled.h3`
    font-size: 1.375rem;
    font-weight: 600;
    width: 100%;
    max-width: 530px;
    margin: 0px;
    transform: translateZ(20px);
`;

export const ProjectImageWrapper = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    img {
        object-fit: cover;
    }
`;

export const Technology = styled.div`
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    padding: 0.3125rem 0.625rem;
    font-size: 0.75rem;
    color: white;
    border-radius: 4px;
    margin-right: 0.625rem;
    margin-bottom: 0.625rem;
    img {
        margin-right: 0.625rem !important;
    }
`;

export const TechnologiesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    max-width: 340px;
    position: relative;
    transform: translateZ(20px);
`;
interface IProjectCardContainer {
    bgColor: string;
}
export const ProjectCardContainer = styled.a<IProjectCardContainer>`
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${props => props.bgColor};
    padding: 2.5rem 1.375rem 1.25rem 1.5625rem;
    height: 100%;
    width: 100%;
    position: relative;
    transform-style: preserve-3d;
    transform: perspective(1000px);
`;

export const ProjectCardWrapper = styled.li`
    margin-bottom: 3.125rem;
    width: 100%;

    @media (min-width: 1200px) {
        width: 50%;
        &:nth-child(odd) {
            padding-right: 1.5625rem;
        }
        &:nth-child(even) {
            padding-left: 1.5625rem;
        }
    }
    @media (min-width: 600px) {
        min-height: 400px;
        ${ProjectImage} {
            display: block;
        }
    }
`;
