import { useMemo } from "react";
import { ImageProps } from "next/image";
import Image from "next/image";

export function NextExternalImage({ src, alt, ...rest }: ImageProps) {
    const source = useMemo(() => {
        return `${process.env.NEXT_PUBLIC_CDN}/images/${src}`;
    }, [src]);
    return <Image src={source} alt={alt} {...rest} />;
}
