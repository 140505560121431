import { FadeUp } from "../FadeUp";
import { SubHeading, Heading } from "./style";

interface IProps {
    heading: string;
    subHeading?: string;
    headingId?: string;
}
export const SectionTitle: React.FC<IProps> = ({
    heading,
    subHeading,
    headingId
}) => {
    return (
        <header className="section-title" id={headingId}>
            {subHeading && (
                <FadeUp>
                    <SubHeading>{subHeading}</SubHeading>
                </FadeUp>
            )}
            <FadeUp delay={0.4}>
                <Heading>{heading}</Heading>
            </FadeUp>
        </header>
    );
};
