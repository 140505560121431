import { Button } from "components/ui/Button";
import { Container } from "components/ui/Container";
import {
    Heading,
    HeroButtons,
    HeroSection,
    HeroService,
    HeroServices,
    LeadingText,
    SubHeading
} from "./style";
import Link from "next/link";
import { BgCircles } from "./BgCircles";
import { FadeUp } from "components/ui/FadeUp";

export const Hero: React.FC = () => {
    const handleMouseEnter = () => {
        const bgCircles = document.querySelectorAll(".bg-circle");
        bgCircles.forEach(bc => {
            (bc as HTMLElement).style.transform =
                "translate(-50%, -50%) scale(1.1)";
        });
    };
    const handleMouseLeave = () => {
        const bgCircles = document.querySelectorAll(".bg-circle");
        bgCircles.forEach(bc => {
            (bc as HTMLElement).style.transform =
                "translate(-50%, -50%) scale(1)";
        });
    };
    return (
        <HeroSection id="home">
            <Container>
                <FadeUp>
                    <SubHeading>Design &amp; Code</SubHeading>
                </FadeUp>
                <FadeUp delay={0.3}>
                    <Heading>Solutions for targeted customers</Heading>
                </FadeUp>
                <FadeUp delay={0.4}>
                    <LeadingText>
                        Creating leading websites with experiences that matter
                        once a customer enters.
                    </LeadingText>
                </FadeUp>
                <HeroButtons>
                    <FadeUp delay={0.6}>
                        <Link prefetch={false} href="#work">
                            <Button
                                aria-label="Previous work"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                Previous Work
                            </Button>
                        </Link>
                    </FadeUp>
                    <FadeUp delay={0.9}>
                        <Link
                            href="/contact"
                            aria-label="Hire me"
                            prefetch={false}
                        >
                            <Button
                                aria-label="Hire me"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                secondary={true}
                            >
                                Hire Me
                            </Button>
                        </Link>
                    </FadeUp>
                </HeroButtons>
                <HeroServices>
                    <FadeUp delay={0.9}>
                        <HeroService>Front-End engineering</HeroService>
                    </FadeUp>
                    <FadeUp delay={1}>
                        <HeroService>Back-End engineering</HeroService>
                    </FadeUp>
                    <FadeUp delay={1.1}>
                        <HeroService>UI/UX Design</HeroService>
                    </FadeUp>
                </HeroServices>
            </Container>
            <BgCircles />
        </HeroSection>
    );
};
