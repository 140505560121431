import { Container } from "components/ui/Container";
import { SectionTitle } from "components/ui/SectionTitle";
import {
    About,
    ServicesContainer,
    ServiceSection,
    ServiceSectionHeader,
    ServiceSingle,
    TechnologiesContainer,
    TechnologiesWrapper,
    TechnologyElement
} from "./style";
import Image from "next/image";
import { BrokenCheck } from "components/ui/Icons";
import { Technology, TechnologyType } from "types/Technology";
import { yearsToNow } from "helpers/date";

interface IProps {
    technologies: Technology[];
}

export const Services: React.FC<IProps> = ({ technologies }) => {
    const dev = technologies.filter(t => t.type === TechnologyType.DEV);
    const design = technologies.filter(t => t.type === TechnologyType.DESIGN);
    return (
        <ServiceSection id="services" className="body-section">
            <Container>
                <ServiceSectionHeader>
                    <SectionTitle
                        subHeading="Services"
                        heading="Latest &amp; Reliable Technologies"
                    />
                    <About>
                        <SectionTitle heading="A little about me" />
                        <p>
                            I am Benali Zakaria a {yearsToNow("1997")} years old
                            self-taught Full stack developer and UI/UX Designer
                            with {yearsToNow("2020-06-01")} years of experience.
                            <br />
                            I&apos;ve worked with various clients from
                            individuals to startups on various projects, which
                            made me gain more experience and be more flexible in
                            finding the right solutions for targeted customers.
                            <br />
                            This also makes me confident that I will provide you
                            with the best solutions for your business.
                        </p>
                        <ServicesContainer>
                            <ServiceSingle>
                                <BrokenCheck />
                                Can-do attitude
                            </ServiceSingle>
                            <ServiceSingle>
                                <BrokenCheck />
                                More than 30 happy clients globally
                            </ServiceSingle>
                            <ServiceSingle>
                                <BrokenCheck />
                                Creative and results-driven
                            </ServiceSingle>
                        </ServicesContainer>
                    </About>
                </ServiceSectionHeader>
                <TechnologiesWrapper>
                    <SectionTitle
                        subHeading="Development"
                        heading="Full Stack"
                        headingId="dev-technologies"
                    />
                    <TechnologiesContainer aria-labelledby="dev-technologies">
                        {dev.map((d, index) => (
                            <TechnologyElement key={index}>
                                <Image
                                    height="35"
                                    width="35"
                                    src={d.icon.url}
                                    alt={`${d.name} Technology`}
                                />
                                {d.name}
                            </TechnologyElement>
                        ))}
                    </TechnologiesContainer>
                    <hr />
                    <SectionTitle
                        subHeading="Design"
                        heading="UI &amp; UX Design"
                        headingId="design-technologies"
                    />
                    <TechnologiesContainer aria-labelledby="design-technologies">
                        {design.map((d, index) => (
                            <TechnologyElement key={index}>
                                <Image
                                    height="35"
                                    width="35"
                                    src={d.icon.url}
                                    alt={`${d.name} Technology`}
                                />
                                {d.name}
                            </TechnologyElement>
                        ))}
                    </TechnologiesContainer>
                </TechnologiesWrapper>
            </Container>
        </ServiceSection>
    );
};
