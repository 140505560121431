import { FindMe } from "components/Index/FindMe";
import { Hero } from "components/Index/Hero";
import { Services } from "components/Index/Services";
import { WhyMe } from "components/Index/Why";
import { Work } from "components/Index/Work";
import { graphqlClient } from "graphql/client";
import { GET_PROJECTS_ALL_TECH } from "graphql/queries";
import type { GetStaticProps, GetStaticPropsResult, NextPage } from "next";
import { Project } from "types/Project";
import { Technology } from "types/Technology";
import { HomeSeo } from "components/SEO";

interface HomeProps {
    technologies: Technology[];
    projects: Project[];
}

const Home: NextPage<HomeProps> = ({ technologies, projects }) => {
    return (
        <>
            <HomeSeo />
            <Hero />
            <Work projects={projects} />
            <WhyMe />
            <Services technologies={technologies} />
            <FindMe />
        </>
    );
};

export default Home;

export const getStaticProps: GetStaticProps = async (): Promise<
    GetStaticPropsResult<HomeProps>
> => {
    const {
        data: { projects, technologies }
    } = await graphqlClient.query({
        query: GET_PROJECTS_ALL_TECH
    });

    return {
        props: {
            technologies,
            projects
        },
        revalidate: 30
    };
};
